export default {
  key: 'Ab',
  suffix: '11',
  positions: [
    {
      frets: '444544',
      fingers: '111211',
      barres: 4,
      capo: true
    },
    {
      frets: '666678',
      fingers: '111123',
      barres: 6,
      capo: true
    },
    {
      frets: 'xbab99',
      fingers: '032411',
      barres: 9,
      capo: true
    },
    {
      frets: 'xbbbdb',
      fingers: '011131',
      barres: 11,
      capo: true
    }
  ]
};
