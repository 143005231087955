export default {
  key: 'C#',
  suffix: 'dim',
  positions: [
    {
      frets: 'x42x23',
      fingers: '041023'
    },
    {
      frets: 'x4565x',
      fingers: '012430'
    },
    {
      frets: '97x98x',
      fingers: '310420'
    },
    {
      frets: 'xxbcxc',
      fingers: '001203'
    }
  ]
};
