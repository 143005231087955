export default {
  key: 'C',
  suffix: 'madd9',
  positions: [
    {
      frets: 'x31033',
      fingers: '021034'
    },
    {
      frets: 'x30543',
      fingers: '010432'
    },
    {
      frets: '860788',
      fingers: '310244',
      barres: 8
    },
    {
      frets: 'xxa88a',
      fingers: '003114',
      barres: 8,
      capo: true
    }
  ]
};
