export default [
  {
    key: "F#",
    suffix: "major",
    positions: [
      {
        frets: [2, 4, 4, 3, 2, 2],
        fingers: [1, 3, 4, 2, 1, 1],
        barres: [2],
        capo: true,
        baseFret: 1,
        midi: [42, 49, 54, 58, 61, 66],
      },
      {
        frets: [-1, 1, 1, 3, 4, 3],
        fingers: [0, 1, 1, 2, 4, 3],
        barres: [1],
        capo: true,
        baseFret: 4,
        midi: [49, 54, 61, 66, 70],
      },
      {
        frets: [1, 4, 3, 1, 2, 1],
        fingers: [1, 4, 3, 1, 2, 1],
        barres: [1],
        capo: true,
        baseFret: 6,
        midi: [46, 54, 58, 61, 66, 70],
      },
      {
        frets: [1, 1, 3, 3, 3, 1],
        fingers: [1, 1, 2, 3, 4, 1],
        barres: [1],
        capo: true,
        baseFret: 9,
        midi: [49, 54, 61, 66, 70, 73],
      },
    ],
  },
  {
    key: "F#",
    suffix: "minor",
    positions: [
      {
        frets: [2, 4, 4, 2, 2, 2],
        fingers: [1, 3, 4, 1, 1, 1],
        barres: [2],
        capo: true,
        baseFret: 1,
        midi: [42, 49, 54, 57, 61, 66],
      },
      {
        frets: [-1, 1, 1, 3, 4, 2],
        fingers: [0, 1, 1, 3, 4, 2],
        barres: [1],
        capo: true,
        baseFret: 4,
        midi: [49, 54, 61, 66, 69],
      },
      {
        frets: [-1, -1, 3, 2, 3, 1],
        fingers: [0, 0, 3, 2, 4, 1],
        baseFret: 5,
        barres: [],
        midi: [57, 61, 66, 69],
      },
      {
        frets: [1, 1, 3, 3, 2, 1],
        fingers: [1, 1, 3, 4, 2, 1],
        barres: [1],
        capo: true,
        baseFret: 9,
        midi: [49, 54, 61, 66, 69, 73],
      },
    ],
  },
  {
    key: "F#",
    suffix: "7",
    positions: [
      {
        frets: [2, 4, 2, 3, 2, 2],
        fingers: [1, 3, 1, 2, 1, 1],
        barres: [2],
        capo: true,
        baseFret: 1,
        midi: [42, 49, 52, 58, 61, 66],
      },
      {
        frets: [-1, 1, 1, 3, 2, 3],
        fingers: [0, 1, 1, 3, 2, 4],
        barres: [1],
        capo: true,
        baseFret: 4,
        midi: [49, 54, 61, 64, 70],
      },
      {
        frets: [-1, 3, 2, 3, 1, -1],
        fingers: [0, 3, 2, 4, 1, 0],
        baseFret: 7,
        barres: [],
        midi: [54, 58, 64, 66],
      },
      {
        frets: [1, 1, 3, 1, 3, 1],
        fingers: [1, 1, 3, 1, 4, 1],
        barres: [1],
        capo: true,
        baseFret: 9,
        midi: [49, 54, 61, 64, 70, 73],
      },
    ],
  },
  {
    key: "F#",
    suffix: "m7",
    positions: [
      {
        frets: [2, -1, 2, 2, 2, -1],
        fingers: [2, 0, 3, 3, 3, 0],
        baseFret: 1,
        barres: [],
        midi: [42, 52, 57, 61],
      },
      {
        frets: [1, -1, 1, 1, 1, -1],
        fingers: [2, 0, 3, 3, 3, 0],
        baseFret: 14,
        barres: [],
        midi: [54, 64, 69, 73],
      },
      {
        frets: [2, 4, 2, 2, 2, 2],
        fingers: [1, 3, 1, 1, 1, 1],
        barres: [2],
        capo: true,
        baseFret: 1,
        midi: [42, 49, 52, 57, 61, 66],
      },
      {
        frets: [-1, -1, 1, 3, 2, 2],
        fingers: [0, 0, 1, 4, 2, 3],
        baseFret: 4,
        barres: [],
        midi: [54, 61, 64, 69],
      },
      {
        frets: [1, 1, 3, 1, 2, 1],
        fingers: [1, 1, 3, 1, 2, 1],
        barres: [1],
        capo: true,
        baseFret: 9,
        midi: [49, 54, 61, 64, 69, 73],
      },
      {
        frets: [-1, -1, 2, 2, 1, 3],
        fingers: [0, 0, 2, 3, 1, 4],
        baseFret: 10,
        barres: [],
        midi: [61, 66, 69, 76],
      },
    ],
  },
];
