export default [
  {
    key: "C#",
    suffix: "major",
    positions: [
      {
        frets: [-1, 4, 3, 1, 2, 1],
        fingers: [0, 4, 3, 1, 2, 1],
        barres: [1],
        baseFret: 1,
        midi: [49, 53, 56, 61, 65],
      },
      {
        frets: [1, 1, 3, 3, 3, 1],
        fingers: [1, 1, 2, 3, 4, 1],
        barres: [1],
        capo: true,
        baseFret: 4,
        midi: [44, 49, 56, 61, 65, 68],
      },
      {
        frets: [4, 3, 1, 1, 1, 4],
        fingers: [3, 2, 1, 1, 1, 4],
        barres: [1],
        baseFret: 6,
        midi: [49, 53, 56, 61, 65, 73],
      },
      {
        frets: [1, 3, 3, 2, 1, 1],
        fingers: [1, 3, 4, 2, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 9,
        midi: [49, 56, 61, 65, 68, 73],
      },
    ],
  },
  {
    key: "C#",
    suffix: "minor",
    positions: [
      {
        frets: [-1, 4, 2, 1, 2, -1],
        fingers: [0, 4, 2, 1, 3, 0],
        baseFret: 1,
        barres: [],
        midi: [49, 52, 56, 61],
      },
      {
        frets: [1, 1, 3, 3, 2, 1],
        fingers: [1, 1, 3, 4, 2, 1],
        barres: [1],
        capo: true,
        baseFret: 4,
        midi: [44, 49, 56, 61, 64, 68],
      },
      {
        frets: [4, 2, 1, 1, -1, 4],
        fingers: [3, 2, 1, 1, 0, 4],
        barres: [1],
        baseFret: 6,
        midi: [49, 52, 56, 61, 73],
      },
      {
        frets: [1, 3, 3, 1, 1, 1],
        fingers: [1, 3, 4, 1, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 9,
        midi: [49, 56, 61, 64, 68, 73],
      },
    ],
  },
  {
    key: "C#",
    suffix: "7",
    positions: [
      {
        frets: [-1, 4, 3, 4, 2, -1],
        fingers: [0, 3, 2, 4, 1, 0],
        baseFret: 1,
        barres: [],
        midi: [49, 53, 59, 61],
      },
      {
        frets: [-1, 1, 3, 1, 3, 1],
        fingers: [0, 1, 3, 1, 4, 1],
        barres: [1],
        capo: true,
        baseFret: 4,
        midi: [49, 56, 59, 65, 68],
      },
      {
        frets: [4, 3, 1, 1, 1, 2],
        fingers: [4, 3, 1, 1, 1, 2],
        barres: [1],
        capo: true,
        baseFret: 6,
        midi: [49, 53, 56, 61, 65, 71],
      },
      {
        frets: [1, 3, 1, 2, 1, 1],
        fingers: [1, 3, 1, 2, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 9,
        midi: [49, 56, 59, 65, 68, 73],
      },
    ],
  },
  {
    key: "C#",
    suffix: "m7",
    positions: [
      {
        frets: [1, -1, 1, 1, 1, -1],
        fingers: [2, 0, 3, 3, 3, 0],
        baseFret: 9,
        barres: [],
        midi: [49, 59, 64, 68],
      },
      {
        frets: [-1, 1, 3, 1, 2, 1],
        fingers: [0, 1, 3, 1, 2, 1],
        barres: [1],
        capo: true,
        baseFret: 4,
        midi: [49, 56, 59, 64, 68],
      },
      {
        frets: [-1, -1, 2, 2, 1, 3],
        fingers: [0, 0, 2, 3, 1, 4],
        baseFret: 5,
        barres: [],
        midi: [56, 61, 64, 71],
      },
      {
        frets: [1, 3, 1, 1, 1, 1],
        fingers: [1, 4, 1, 1, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 9,
        midi: [49, 56, 59, 64, 68, 73],
      },
      {
        frets: [-1, -1, 1, 3, 2, 2],
        fingers: [0, 0, 1, 4, 2, 3],
        baseFret: 11,
        barres: [],
        midi: [61, 68, 71, 76],
      },
    ],
  },
];
