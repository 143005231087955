export default {
  key: 'G',
  suffix: '/F',
  positions: [
    {
      frets: '120033',
      fingers: '120034'
    },
    {
      frets: '120003',
      fingers: '120003'
    },
    {
      frets: 'xx3433',
      fingers: '001211'
    }
  ]
};
