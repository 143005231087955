export default {
  key: 'E',
  suffix: 'maj13',
  positions: [
    {
      frets: '021122',
      fingers: '021134',
      barres: 1
    },
    {
      frets: 'x76644',
      fingers: '042311',
      barres: 4
    },
    {
      frets: 'x77899',
      fingers: '011234',
      barres: 7,
      capo: true
    },
    {
      frets: 'cbbbcb',
      fingers: '211131',
      barres: 11,
      capo: true
    }
  ]
};
