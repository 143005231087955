export default {
  key: 'F#',
  suffix: 'add9',
  positions: [
    {
      frets: '21x122',
      fingers: '310244',
      barres: 2
    },
    {
      frets: 'xx4324',
      fingers: '003214'
    },
    {
      frets: 'x98696',
      fingers: '032141',
      barres: 6,
      capo: true
    },
    {
      frets: 'x98x99',
      fingers: '021034'
    }
  ]
};
