export default {
  key: 'A',
  suffix: 'm7',
  positions: [
    {
      frets: 'x02010',
      fingers: '002010'
    },
    {
      frets: 'x02213',
      fingers: '002314'
    },
    {
      frets: '5x555x',
      fingers: '203330'
    },
    {
      frets: 'x05555',
      fingers: '001111',
      barres: 5
    },
    {
      frets: '575555',
      fingers: '131111',
      barres: 5,
      capo: true
    },
    {
      frets: 'x77988',
      fingers: '011423',
      barres: 7,
      capo: true
    }
  ]
};
