export default {
  key: 'F',
  suffix: '9#11',
  positions: [
    {
      frets: '101001',
      fingers: '102003'
    },
    {
      frets: 'xx3445',
      fingers: '001234'
    },
    {
      frets: 'x87887',
      fingers: '021341',
      barres: 7,
      capo: true
    },
    {
      frets: 'x898a8',
      fingers: '012131',
      barres: 8,
      capo: true
    }
  ]
};
