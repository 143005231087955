export default {
  key: 'G',
  suffix: 'aug7',
  positions: [
    {
      frets: '321001',
      fingers: '431002'
    },
    {
      frets: '3x344x',
      fingers: '102340'
    },
    {
      frets: 'xx5867',
      fingers: '001423'
    },
    {
      frets: 'xadacb',
      fingers: '014132',
      barres: 10,
      capo: true
    }
  ]
};
