export default {
  key: 'Bb',
  suffix: '7b5',
  positions: [
    {
      frets: 'x1213x',
      fingers: '012130',
      barres: 1,
      capo: true
    },
    {
      frets: '6x6750',
      fingers: '203410'
    },
    {
      frets: 'xx899a',
      fingers: '001234'
    },
    {
      frets: 'xdcdb0',
      fingers: '032410'
    }
  ]
};
