export default {
  key: 'Bb',
  suffix: 'maj7b5',
  positions: [
    {
      frets: 'x1223x',
      fingers: '012340'
    },
    {
      frets: '657755',
      fingers: '213411',
      barres: 5,
      capo: true
    },
    {
      frets: '6777x0',
      fingers: '123400'
    },
    {
      frets: 'xx89aa',
      fingers: '001234'
    }
  ]
};
