export default [
  {
    key: "A",
    suffix: "major",
    positions: [
      {
        frets: [-1, 0, 2, 2, 2, 0],
        fingers: [0, 0, 1, 2, 3, 0],
        baseFret: 1,
        barres: [],
        midi: [45, 52, 57, 61, 64],
      },
      {
        frets: [-1, 0, 1, 1, 1, 4],
        fingers: [0, 0, 1, 1, 1, 4],
        barres: [1],
        capo: true,
        baseFret: 2,
        midi: [45, 52, 57, 61, 69],
      },
      {
        frets: [1, 3, 3, 2, 1, 1],
        fingers: [1, 3, 4, 2, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 5,
        midi: [45, 52, 57, 61, 64, 69],
      },
      {
        frets: [-1, 0, 1, 3, 4, 3],
        fingers: [0, 0, 1, 2, 4, 3],
        baseFret: 7,
        barres: [],
        midi: [45, 57, 64, 69, 73],
      },
    ],
  },
  {
    key: "A",
    suffix: "minor",
    positions: [
      {
        frets: [-1, 0, 2, 2, 1, 0],
        fingers: [0, 0, 2, 3, 1, 0],
        baseFret: 1,
        barres: [],
        midi: [45, 52, 57, 60, 64],
      },
      {
        frets: [-1, 0, 1, 4, 4, 4],
        fingers: [0, 0, 1, 4, 4, 4],
        barres: [4],
        capo: true,
        baseFret: 2,
        midi: [45, 52, 60, 64, 69],
      },
      {
        frets: [1, 3, 3, 1, 1, 1],
        fingers: [1, 3, 4, 1, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 5,
        midi: [45, 52, 57, 60, 64, 69],
      },
      {
        frets: [-1, 0, 1, 3, 4, 2],
        fingers: [0, 0, 1, 3, 4, 2],
        baseFret: 7,
        barres: [],
        midi: [45, 57, 64, 69, 72],
      },
    ],
  },
  {
    key: "A",
    suffix: "7",
    positions: [
      {
        frets: [-1, 0, 2, 0, 2, 0],
        fingers: [0, 0, 2, 0, 3, 0],
        baseFret: 1,
        barres: [],
        midi: [45, 52, 55, 61, 64],
      },
      {
        frets: [-1, 0, 2, 2, 2, 3],
        fingers: [0, 0, 1, 1, 1, 2],
        barres: [2],
        capo: true,
        baseFret: 1,
        midi: [45, 52, 57, 61, 67],
      },
      {
        frets: [1, 3, 1, 2, 1, 1],
        fingers: [1, 3, 1, 2, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 5,
        midi: [45, 52, 55, 61, 64, 69],
      },
      {
        frets: [-1, 0, 1, 3, 2, 3],
        fingers: [0, 0, 1, 3, 2, 4],
        baseFret: 7,
        barres: [],
        midi: [45, 57, 64, 67, 73],
      },
    ],
  },
  {
    key: "A",
    suffix: "m7",
    positions: [
      {
        frets: [-1, 0, 2, 0, 1, 0],
        fingers: [0, 0, 2, 0, 1, 0],
        baseFret: 1,
        barres: [],
        midi: [45, 52, 55, 60, 64],
      },
      {
        frets: [-1, 0, 2, 2, 1, 3],
        fingers: [0, 0, 2, 3, 1, 4],
        baseFret: 1,
        barres: [],
        midi: [45, 52, 57, 60, 67],
      },
      {
        frets: [1, -1, 1, 1, 1, -1],
        fingers: [2, 0, 3, 3, 3, 0],
        baseFret: 5,
        barres: [],
        midi: [45, 55, 60, 64],
      },
      {
        frets: [-1, 0, 1, 1, 1, 1],
        fingers: [0, 0, 1, 1, 1, 1],
        barres: [1],
        baseFret: 5,
        midi: [45, 55, 60, 64, 69],
      },
      {
        frets: [1, 3, 1, 1, 1, 1],
        fingers: [1, 3, 1, 1, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 5,
        midi: [45, 52, 55, 60, 64, 69],
      },
      {
        frets: [-1, 1, 1, 3, 2, 2],
        fingers: [0, 1, 1, 4, 2, 3],
        barres: [1],
        capo: true,
        baseFret: 7,
        midi: [52, 57, 64, 67, 72],
      },
    ],
  },
];
