export default {
  key: 'A',
  suffix: '7b9',
  positions: [
    {
      frets: 'x02323',
      fingers: '001213',
      barres: 2
    },
    {
      frets: '575656',
      fingers: '141213',
      barres: 5,
      capo: true
    },
    {
      frets: 'xx7686',
      fingers: '002131',
      barres: 6,
      capo: true
    },
    {
      frets: 'xcbcbc',
      fingers: '021314',
      barres: 11,
      capo: true
    }
  ]
};
