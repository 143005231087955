export default {
  key: 'B',
  suffix: '7b5',
  positions: [
    {
      frets: 'x21201',
      fingers: '031402'
    },
    {
      frets: 'x2324x',
      fingers: '012130'
    },
    {
      frets: '7x786x',
      fingers: '203410'
    },
    {
      frets: 'xx9aab',
      fingers: '001234'
    }
  ]
};
