export default {
  key: 'A',
  suffix: '5',
  positions: [
    {
      frets: '57xxxx',
      fingers: '130000'
    },
    {
      frets: 'x02xxx',
      fingers: '001000'
    },
    {
      frets: '577xxx',
      fingers: '134000'
    }
  ]
};
