export default {
  key: 'Ab',
  suffix: 'maj9',
  positions: [
    {
      frets: 'x11113',
      fingers: '011114',
      barres: 1,
      capo: true
    },
    {
      frets: '435343',
      fingers: '214131',
      barres: 3,
      capo: true
    },
    {
      frets: '465546',
      fingers: '132214',
      barres: 4,
      capo: true
    },
    {
      frets: 'bbacbx',
      fingers: '221430',
      barres: 11
    }
  ]
};
