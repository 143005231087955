export default {
  key: 'F#',
  suffix: '7sus4',
  positions: [
    {
      frets: '242422',
      fingers: '131411',
      barres: 2,
      capo: true
    },
    {
      frets: 'xx4657',
      fingers: '001324'
    },
    {
      frets: 'x99977',
      fingers: '023411',
      barres: 7,
      capo: true
    },
    {
      frets: '99b9c9',
      fingers: '113141',
      barres: 9,
      capo: true
    }
  ]
};
