export default {
  key: 'C',
  suffix: 'mmaj11',
  positions: [
    {
      frets: 'x31001',
      fingers: '031002'
    },
    {
      frets: '333443',
      fingers: '111231',
      barres: 3,
      capo: true
    },
    {
      frets: '88988a',
      fingers: '112114',
      barres: 8,
      capo: true
    },
    {
      frets: 'xxaacb',
      fingers: '001132',
      barres: 10,
      capo: true
    }
  ]
};
