export default [
  {
    key: "F",
    suffix: "major",
    positions: [
      {
        frets: [1, 3, 3, 2, 1, 1],
        fingers: [1, 3, 4, 2, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 1,
        midi: [41, 48, 53, 57, 60, 65],
      },
      {
        frets: [-1, -1, 3, 2, 1, 1],
        fingers: [-1, -1, 3, 2, 1, 1],
        barres: [1],
        baseFret: 1,
        midi: [53, 57, 60, 65],
      },
      {
        frets: [-1, -1, 1, 3, 4, 3],
        fingers: [0, 0, 1, 2, 4, 3],
        baseFret: 3,
        barres: [],
        midi: [53, 60, 65, 69],
      },
      {
        frets: [-1, 4, 3, 1, 2, 1],
        fingers: [0, 4, 3, 1, 2, 1],
        barres: [1],
        capo: true,
        baseFret: 5,
        midi: [53, 57, 60, 65, 69],
      },
      {
        frets: [-1, 1, 3, 3, 3, 1],
        fingers: [0, 1, 2, 3, 4, 1],
        barres: [1],
        capo: true,
        baseFret: 8,
        midi: [53, 60, 65, 69, 72],
      },
    ],
  },
  {
    key: "F",
    suffix: "minor",
    positions: [
      {
        frets: [1, 3, 3, 1, 1, 1],
        fingers: [1, 3, 4, 1, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 1,
        midi: [41, 48, 53, 56, 60, 65],
      },
      {
        frets: [-1, -1, 1, 3, 4, 2],
        fingers: [0, 0, 1, 3, 4, 2],
        baseFret: 3,
        barres: [],
        midi: [53, 60, 65, 68],
      },
      {
        frets: [-1, 1, 3, 3, 2, 1],
        fingers: [0, 1, 3, 4, 2, 1],
        barres: [1],
        capo: true,
        baseFret: 8,
        midi: [53, 60, 65, 68, 72],
      },
      {
        frets: [4, 2, 1, 1, -1, -1],
        fingers: [4, 2, 1, 1, 0, 0],
        barres: [1],
        capo: true,
        baseFret: 10,
        midi: [53, 56, 60, 65],
      },
    ],
  },
  {
    key: "F",
    suffix: "7",
    positions: [
      {
        frets: [1, 3, 1, 2, 1, 1],
        fingers: [1, 3, 1, 2, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 1,
        midi: [41, 48, 51, 57, 60, 65],
      },
      {
        frets: [-1, 1, 1, 3, 2, 3],
        fingers: [0, 1, 1, 3, 2, 4],
        barres: [1],
        capo: true,
        baseFret: 3,
        midi: [48, 53, 60, 63, 69],
      },
      {
        frets: [1, 1, 3, 1, 3, 1],
        fingers: [1, 1, 3, 1, 4, 1],
        barres: [1],
        capo: true,
        baseFret: 8,
        midi: [48, 53, 60, 63, 69, 72],
      },
      {
        frets: [-1, -1, 1, 1, 1, 2],
        fingers: [0, 0, 1, 1, 1, 2],
        barres: [1],
        capo: true,
        baseFret: 10,
        midi: [60, 65, 69, 75],
      },
    ],
  },
  {
    key: "F",
    suffix: "m7",
    positions: [
      {
        frets: [1, -1, 1, 1, 1, -1],
        fingers: [2, 0, 3, 3, 3, 0],
        baseFret: 1,
        barres: [],
        midi: [41, 51, 56, 60],
      },
      {
        frets: [1, -1, 1, 1, 1, -1],
        fingers: [2, 0, 3, 3, 3, 0],
        baseFret: 13,
        barres: [],
        midi: [53, 63, 68, 72],
      },
      {
        frets: [1, 3, 1, 1, 1, 1],
        fingers: [1, 3, 1, 1, 1, 1],
        barres: [1],
        capo: true,
        baseFret: 1,
        midi: [41, 48, 51, 56, 60, 65],
      },
      {
        frets: [-1, -1, 1, 3, 2, 2],
        fingers: [0, 0, 1, 4, 2, 3],
        baseFret: 3,
        barres: [],
        midi: [53, 60, 63, 68],
      },
      {
        frets: [1, 1, 3, 1, 2, 1],
        fingers: [1, 1, 3, 1, 2, 1],
        barres: [1],
        capo: true,
        baseFret: 8,
        midi: [48, 53, 60, 63, 68, 72],
      },
      {
        frets: [-1, -1, 2, 2, 1, 3],
        fingers: [0, 0, 2, 3, 1, 4],
        baseFret: 9,
        barres: [],
        midi: [60, 65, 68, 75],
      },
    ],
  },
];
