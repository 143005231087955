export default {
  key: 'C#',
  suffix: 'mmaj11',
  positions: [
    {
      frets: 'x42542',
      fingers: '031431',
      barres: 2,
      capo: true
    },
    {
      frets: '444554',
      fingers: '111231',
      barres: 4,
      capo: true
    },
    {
      frets: '99a99b',
      fingers: '112114',
      barres: 9,
      capo: true
    },
    {
      frets: 'xxbbdc',
      fingers: '001132',
      barres: 11,
      capo: true
    }
  ]
};
