export default {
  key: 'A',
  suffix: 'm/G#',
  positions: [
    {
      frets: '432555',
      fingers: '321444',
      barres: 5
    },
    {
      frets: '432255',
      fingers: '321144',
      barres: 2
    }
  ]
};
