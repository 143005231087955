export default {
  key: 'E',
  suffix: 'm9',
  positions: [
    {
      frets: '020002',
      fingers: '020004'
    },
    {
      frets: '022032',
      fingers: '012043'
    },
    {
      frets: 'x75777',
      fingers: '021344',
      barres: 7
    },
    {
      frets: '0acbca',
      fingers: '013241',
      barres: 10
    }
  ]
};
