export default {
  key: 'E',
  suffix: '/F#',
  positions: [
    {
      frets: '222100',
      fingers: '234100'
    },
    {
      frets: 'xx4454',
      fingers: '001121',
      barres: 4
    },
    {
      frets: '222454',
      fingers: '111343',
      barres: 2
    }
  ]
};
