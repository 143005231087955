export default {
  key: 'Ab',
  suffix: 'm69',
  positions: [
    {
      frets: '4x3446',
      fingers: '201334',
      barres: 4
    },
    {
      frets: '466466',
      fingers: '122134',
      barres: [4, 6],
      capo: true
    },
    {
      frets: '766866',
      fingers: '211311',
      barres: 6,
      capo: true
    },
    {
      frets: 'xb9abb',
      fingers: '031244',
      barres: 11
    }
  ]
};
