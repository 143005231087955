export default {
  key: 'C',
  suffix: '7b5',
  positions: [
    {
      frets: 'xx2312',
      fingers: '002413'
    },
    {
      frets: 'x3435x',
      fingers: '012130',
      barres: 3
    },
    {
      frets: '8x8970',
      fingers: '203410'
    },
    {
      frets: 'xxabbc',
      fingers: '001234'
    }
  ]
};
