export default {
  key: 'G',
  suffix: 'maj9',
  positions: [
    {
      frets: '300002',
      fingers: '200001'
    },
    {
      frets: '324232',
      fingers: '214131',
      barres: 2,
      capo: true
    },
    {
      frets: 'xx4435',
      fingers: '002314'
    },
    {
      frets: 'xa9bax',
      fingers: '021430'
    }
  ]
};
