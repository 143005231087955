export default {
  key: 'Ab',
  suffix: 'm9',
  positions: [
    {
      frets: '411102',
      fingers: '411203',
      barres: 1
    },
    {
      frets: '464446',
      fingers: '131114',
      barres: 4,
      capo: true
    },
    {
      frets: '798897',
      fingers: '132241',
      barres: 8
    },
    {
      frets: 'xb9bbb',
      fingers: '021344',
      barres: 11
    }
  ]
};
