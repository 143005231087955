export default {
  key: 'C#',
  suffix: 'alt',
  positions: [
    {
      frets: 'x43021',
      fingers: '043021'
    },
    {
      frets: 'x45063',
      fingers: '023041'
    },
    {
      frets: 'x4566x',
      fingers: '012340'
    },
    {
      frets: 'xxba89',
      fingers: '004312'
    }
  ]
};
