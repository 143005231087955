export default {
  key: 'E',
  suffix: 'm/D#',
  positions: [
    {
      frets: 'xx1000',
      fingers: '001000'
    },
    {
      frets: 'x6545x',
      fingers: '042130'
    },
    {
      frets: 'x6998x',
      fingers: '013420'
    }
  ]
};
