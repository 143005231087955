export default {
  key: 'C#',
  suffix: '7b5',
  positions: [
    {
      frets: 'x43001',
      fingers: '043001'
    },
    {
      frets: 'x4546x',
      fingers: '012130',
      barres: 4,
      capo: true
    },
    {
      frets: '9x9a8x',
      fingers: '203410'
    },
    {
      frets: 'xxcdde',
      fingers: '001234'
    }
  ]
};
