export default {
  key: 'Ab',
  suffix: '7sus4',
  positions: [
    {
      frets: 'xx1122',
      fingers: '001122',
      barres: [1, 2],
      capo: true
    },
    {
      frets: '464644',
      fingers: '131411',
      barres: 4,
      capo: true
    },
    {
      frets: 'xbbb99',
      fingers: '023411',
      barres: 9,
      capo: true
    },
    {
      frets: 'bbdbeb',
      fingers: '113141',
      barres: 11,
      capo: true
    }
  ]
};
