export default [
  {
    key: "E",
    suffix: "major",
    positions: [
      {
        frets: [0, 2, 2, 1, 0, 0],
        fingers: [0, 2, 3, 1, 0, 0],
        baseFret: 1,
        barres: [],
        midi: [40, 47, 52, 56, 59, 64],
      },
      {
        frets: [-1, -1, 1, 3, 4, 3],
        fingers: [0, 0, 1, 2, 4, 3],
        baseFret: 2,
        barres: [],
        midi: [52, 59, 64, 68],
      },
      {
        frets: [-1, 4, 3, 1, 2, 1],
        fingers: [0, 4, 3, 1, 2, 1],
        barres: [1],
        capo: true,
        baseFret: 4,
        midi: [52, 56, 59, 64, 68],
      },
      {
        frets: [-1, 1, 3, 3, 3, 1],
        fingers: [0, 1, 2, 3, 4, 1],
        barres: [1],
        capo: true,
        baseFret: 7,
        midi: [52, 59, 64, 68, 71],
      },
    ],
  },
  {
    key: "E",
    suffix: "minor",
    positions: [
      {
        frets: [0, 2, 2, 0, 0, 0],
        fingers: [0, 2, 3, 0, 0, 0],
        baseFret: 1,
        barres: [],
        midi: [40, 47, 52, 55, 59, 64],
      },
      {
        frets: [0, 1, 1, 3, 4, 2],
        fingers: [0, 1, 1, 3, 4, 2],
        barres: [1],
        baseFret: 2,
        midi: [40, 47, 52, 59, 64, 67],
      },
      {
        frets: [-1, 1, 3, 3, 2, 1],
        fingers: [0, 1, 3, 4, 2, 1],
        barres: [1],
        capo: true,
        baseFret: 7,
        midi: [52, 59, 64, 67, 71],
      },
      {
        frets: [4, 2, 1, 1, -1, -1],
        fingers: [4, 3, 1, 2, 0, 0],
        baseFret: 9,
        barres: [],
        midi: [52, 55, 59, 64],
      },
    ],
  },
  {
    key: "E",
    suffix: "7",
    positions: [
      {
        frets: [0, 2, 0, 1, 0, 0],
        fingers: [0, 2, 0, 1, 0, 0],
        baseFret: 1,
        barres: [],
        midi: [40, 47, 50, 56, 59, 64],
      },
      {
        frets: [-1, 3, 2, 3, 1, -1],
        fingers: [0, 3, 2, 4, 1, 0],
        baseFret: 5,
        barres: [],
        midi: [52, 56, 62, 64],
      },
      {
        frets: [1, 1, 3, 1, 3, 1],
        fingers: [1, 1, 3, 1, 4, 1],
        barres: [1],
        capo: true,
        baseFret: 7,
        midi: [47, 52, 59, 62, 68, 71],
      },
      {
        frets: [-1, -1, 1, 1, 1, 2],
        fingers: [0, 0, 1, 1, 1, 2],
        barres: [1],
        capo: true,
        baseFret: 9,
        midi: [59, 64, 68, 74],
      },
    ],
  },
  {
    key: "E",
    suffix: "m7",
    positions: [
      {
        frets: [0, 2, 0, 0, 0, 0],
        fingers: [0, 2, 0, 0, 0, 0],
        baseFret: 1,
        barres: [],
        midi: [40, 47, 50, 55, 59, 64],
      },
      {
        frets: [0, 2, 2, 0, 3, 0],
        fingers: [0, 2, 3, 0, 4, 0],
        baseFret: 1,
        barres: [],
        midi: [40, 47, 52, 55, 62, 64],
      },

      {
        frets: [0, 2, 2, 4, 3, 3],
        fingers: [0, 1, 1, 4, 2, 3],
        barres: [2],
        baseFret: 1,
        midi: [40, 47, 52, 59, 62, 67],
      },
      {
        frets: [1, 1, 3, 1, 2, 1],
        fingers: [1, 1, 3, 1, 2, 1],
        barres: [1],
        capo: true,
        baseFret: 7,
        midi: [47, 52, 59, 62, 67, 71],
      },
      {
        frets: [-1, -1, 2, 2, 1, 3],
        fingers: [0, 0, 2, 3, 1, 4],
        baseFret: 8,
        barres: [],
        midi: [59, 64, 67, 74],
      },
      {
        frets: [1, -1, 1, 1, 1, -1],
        fingers: [2, 0, 3, 3, 3, 0],
        baseFret: 12,
        barres: [],
        midi: [52, 62, 67, 71],
      },
      {
        frets: [0, -1, 0, 0, 0, -1],
        fingers: [2, 0, 3, 3, 3, 0],
        baseFret: 1,
        barres: [],
        midi: [40, 50, 55, 59],
      },
    ],
  },
];
