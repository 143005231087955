export default {
  key: 'D',
  suffix: 'm7b5',
  positions: [
    {
      frets: 'xx0111',
      fingers: '000111',
      barres: 1
    },
    {
      frets: 'x53534',
      fingers: '031412',
      barres: 3
    },
    {
      frets: 'x5656x',
      fingers: '013240'
    },
    {
      frets: 'x80a98',
      fingers: '010432'
    }
  ]
};
