export default {
  key: 'F',
  suffix: 'maj7',
  positions: [
    {
      frets: 'xx3210',
      fingers: '003210'
    },
    {
      frets: '132211',
      fingers: '142311',
      barres: 1,
      capo: true
    },
    {
      frets: 'x33555',
      fingers: '011333',
      barres: 3,
      capo: true
    },
    {
      frets: '88a9a8',
      fingers: '113241',
      barres: 8,
      capo: true
    }
  ]
};
