export default {
  key: 'E',
  suffix: 'sus2sus4',
  positions: [
    {
      frets: '002202',
      fingers: '001203'
    },
    {
      frets: '022202',
      fingers: '012304'
    },
    {
      frets: 'x77977',
      fingers: '011311',
      barres: [7],
      capo: true
    },
    {
      frets: 'x74455',
      fingers: '041122',
      barres: [4, 5]
    },
  ]
};
