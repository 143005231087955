export default {
  key: 'B',
  suffix: '9#11',
  positions: [
    {
      frets: 'x21221',
      fingers: '021341',
      barres: 1,
      capo: true
    },
    {
      frets: 'x23242',
      fingers: '012131',
      barres: 2,
      capo: true
    },
    {
      frets: '7x786x',
      fingers: '203410'
    },
    {
      frets: 'xx9aab',
      fingers: '001234'
    }
  ]
};
