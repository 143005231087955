export default {
  key: 'E',
  suffix: '6',
  positions: [
    {
      frets: '022120',
      fingers: '023140'
    },
    {
      frets: '022424',
      fingers: '011314',
      barres: 2,
      capo: true
    },
    {
      frets: 'x7665x',
      fingers: '042310'
    },
    {
      frets: 'x79999',
      fingers: '013333',
      barres: 9
    }
  ]
};
