export default {
  key: 'A',
  suffix: '7#9',
  positions: [
    {
      frets: '575688',
      fingers: '131244',
      barres: [5, 8],
      capo: true
    },
    {
      frets: 'xx7688',
      fingers: '002134'
    },
    {
      frets: 'x0a989',
      fingers: '004213'
    },
    {
      frets: 'xcbcdx',
      fingers: '021340'
    }
  ]
};
