export default {
  key: 'G',
  suffix: '6',
  positions: [
    {
      frets: '320000',
      fingers: '210000'
    },
    {
      frets: '3x243x',
      fingers: '201430'
    },
    {
      frets: 'x55757',
      fingers: '011314',
      barres: 5,
      capo: true
    },
    {
      frets: 'xacccc',
      fingers: '013334',
      barres: 12
    }
  ]
};
