export default {
  key: 'A',
  suffix: 'maj9',
  positions: [
    {
      frets: 'x02424',
      fingers: '001314',
      barres: 2
    },
    {
      frets: '546450',
      fingers: '214130',
      barres: 4
    },
    {
      frets: 'x06657',
      fingers: '002314'
    },
    {
      frets: 'ccbdcx',
      fingers: '221430',
      barres: 12
    }
  ]
};
