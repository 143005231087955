export default {
  key: 'B',
  suffix: 'maj7#5',
  positions: [
    {
      frets: 'x21303',
      fingers: '021304'
    },
    {
      frets: 'x2534x',
      fingers: '014230'
    },
    {
      frets: '76808x',
      fingers: '213030'
    },
    {
      frets: 'bedcbb',
      fingers: '143211',
      barres: 11,
      capo: true
    }
  ]
};
