export default {
  key: 'Eb',
  suffix: 'maj7',
  positions: [
    {
      frets: 'x11333',
      fingers: '011333',
      barres: [1, 3],
      capo: true
    },
    {
      frets: 'x65333',
      fingers: '043111',
      barres: 3
    },
    {
      frets: '668786',
      fingers: '113241',
      barres: 6,
      capo: true
    },
    {
      frets: 'xx888a',
      fingers: '001114',
      barres: 8
    }
  ]
};
