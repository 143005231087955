export default {
  key: 'A',
  suffix: 'maj11',
  positions: [
    {
      frets: 'x00120',
      fingers: '000120'
    },
    {
      frets: 'x00224',
      fingers: '000114',
      barres: 2
    },
    {
      frets: '556657',
      fingers: '112314',
      barres: 5
    },
    {
      frets: 'a09999',
      fingers: '201111',
      barres: 9,
      capo: true
    }
  ]
};
