export default {
  key: 'F',
  suffix: 'maj11',
  positions: [
    {
      frets: '112211',
      fingers: '112311',
      barres: 1,
      capo: true
    },
    {
      frets: 'xx3355',
      fingers: '001134',
      barres: 3,
      capo: true
    },
    {
      frets: '8889a8',
      fingers: '111231',
      barres: 8,
      capo: true
    },
    {
      frets: 'dce0b0',
      fingers: '324010'
    }
  ]
};
