export default {
  key: 'D',
  suffix: 'madd9',
  positions: [
    {
      frets: 'x53230',
      fingers: '042130'
    },
    {
      frets: 'x5325x',
      fingers: '032140'
    },
    {
      frets: 'x57760',
      fingers: '013420'
    },
    {
      frets: 'xxcaac',
      fingers: '003114',
      barres: 10,
      capo: true
    }
  ]
};
