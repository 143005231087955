export default {
  key: 'E',
  suffix: 'dim',
  positions: [
    {
      frets: 'xx23x3',
      fingers: '001203'
    },
    {
      frets: 'x75x56',
      fingers: '041023'
    },
    {
      frets: 'x7898x',
      fingers: '012430'
    },
    {
      frets: 'caxcbx',
      fingers: '310420'
    }
  ]
};
